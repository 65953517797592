import React, {useContext, useEffect, useState} from "react";
import {Routes, Route, useLocation, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import {QueryClient, QueryClientProvider} from "react-query";

import ChartDataLabels from "chartjs-plugin-datalabels";
import {
    CategoryScale,
    Chart,
    RadarController,
    RadialLinearScale,
    Title,
} from "chart.js";

import About from "./pages/home/about";
import Parents from "./pages/home/parents";
import Contributors from "./pages/home/contributors";
import Sponsors from "./pages/home/sponsors";
import Terms from "./pages/home/terms";
import Privacy from "./pages/home/privacy";
import ContentAttribution from "./pages/home/content-attribution";

// import Admin from "./pages/analytics/admin/admin";

import Login from "./pages/auth/login";
import Signup from "./pages/auth/signup";
import ReinitPassword from "./pages/auth/reinit-password";
import ForgotPassword from "./pages/auth/forgot-password";
import AccountValidation from "./pages/auth/account-validation";
import ResendParentConfigMail from "./pages/auth/resend-parent-config-mail";

import Settings from "./pages/analytics/settings";
import {AnalyticsProvider} from "./pages/analytics/analytics-context";

import Analytics from "./pages/analytics/analytics";

import FooterPublic from "./components/footer/footer-public";
import FooterAnalytics from "./components/footer/footer-analytics";
import NavbarPublic from "./components/navbar/navbar-public";
import NavbarAnalytics from "./components/navbar/navbar-analytics";
// import AnalyticsMenu from "./components/analytics/analytics-menu";

//tools
import ImageEditor from "./pages/tools/image-editor/image-editor";

// stylesSheet
import "./App.scss";

// fonts
// import "./fonts/GrilCB.ttf";
import {menuItems} from "./constants/analytics-constants";
import {
    ChildBaseModel,
} from "./interfaces/child-interfaces/child-interface";
import {
    handleLanguage,
    handleChildId,
    handleFamilyData,
} from "./helper/common-helpers/params-helper";
import {Navigate} from "react-router";
import {AuthContext} from "./pages/auth/auth-context";
import {ReferentBaseModel} from "./interfaces/referent-interfaces/parents-interface";

//graphs
Chart.register(
    Title,
    CategoryScale,
    RadialLinearScale,
    RadarController,
    ChartDataLabels
);

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const {i18n} = useTranslation();
    const queryClient = new QueryClient();
    const {isAuthenticated, isInitializing} = useContext(AuthContext);

    const [isMobileScreenSize, setIsMobileScreenSize] = useState(
        window.innerWidth <= 980
    );

    const [referentAccountData, setReferentAccountData] = useState<ReferentBaseModel | null>(
        null
    );
    const [currentChildSelected, setCurrentChildSelected] =
        useState<ChildBaseModel | null>(null);
    const [isVisitingFromUnityApp, setIsVisitingFromUnityApp] = useState(false);

    useEffect(() => {
        if (globalThis.vuplex) {
            setIsVisitingFromUnityApp(true);
        }
    }, []);

    useEffect(() => {
        if (referentAccountData) {
            setCurrentChildSelected((currentChild) => {
                const updatedChild = referentAccountData.children.find(
                    (child) => child.childId === currentChild?.childId
                );
                return updatedChild || referentAccountData.children[0];
            });
        }
    }, [referentAccountData]);
    
    useEffect(() => {
        const queryString = location.search;
        const urlParams = new URLSearchParams(queryString);
        handleLanguage(urlParams.get("lang"), i18n.changeLanguage);
        handleChildId(urlParams.get("childId"));
        handleFamilyData(
            referentAccountData,
            currentChildSelected,
            setCurrentChildSelected,
            i18n.changeLanguage
        );
    }, [
        location.search,
        i18n,
        referentAccountData,
        currentChildSelected,
        setCurrentChildSelected,
    ]);

    useEffect(() => {
        if (!isInitializing && !isAuthenticated && connectedUserPaths.includes(location.pathname)) {
            navigate("/");
        }
    }, [isAuthenticated, isInitializing, location.pathname, navigate]);


    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreenSize(window.innerWidth <= 980);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const publicPaths: string[] = [
        "/",
        "/about",
        "/parents",
        "/contributors",
        "/sponsors",
        "/terms",
        "/privacy",
        "/content-attribution",
        "/login",
        "/login/*",
        "/signup",
        "/signup/*",
        "/accountValidation",
        "/accountValidation/*",
        "/resetPassword",
        "/resetPassword/*",
        "/forgotPassword",
        "/forgotPassword/*",
        "/resendParentConfigMail",
        "/resendParentConfigMail/*",
        "/imageEditor"
    ];

    const connectedUserPaths: string[] = [
        "/dashboard",
        "/analytics",
        "/analytics/*",
        "/settings",
        "/admin",
    ];

    const isPublicPath: boolean = publicPaths.some((path) => {
        if (path.endsWith("/*")) {
            return location.pathname.startsWith(path.slice(0, -1));
        }
        return location.pathname === path;
    });

    const currentMenuItem = menuItems.find((item) =>
        location.pathname.includes(item.route)
    );

    const mobileliteMode =
        currentMenuItem && currentMenuItem.route.startsWith("/analytics/");

    const hideHeaderFooter = location.pathname === '/imageEditor';

    return (
        <div className="App">
            {!hideHeaderFooter && (isPublicPath ?
                    <NavbarPublic isVisitingFromUnityApp={isVisitingFromUnityApp}/>
                    :
                    <NavbarAnalytics
                        isMobileScreenSize={isMobileScreenSize}
                        mobileLiteMode={mobileliteMode}
                        referentData={referentAccountData}
                        currentChildSelected={currentChildSelected}
                        setCurrentChildSelected={setCurrentChildSelected}
                        isVisitingFromUnityApp={isVisitingFromUnityApp}
                    />
            )}
            <Routes>
                <Route path="/" element={<About/>}/>
                <Route path="about" element={<About/>}/>
                <Route path="parents" element={<Parents/>}/>
                <Route path="contributors" element={<Contributors/>}/>
                <Route path="sponsors" element={<Sponsors/>}/>
                <Route path="terms" element={<Terms/>}/>
                <Route path="privacy" element={<Privacy/>}/>
                <Route path="content-attribution" element={<ContentAttribution/>}/>

                <Route path="login" element={<Login/>}/>

                <Route path="signup" element={<Signup/>}/>
                <Route
                    path="/analytics/*"
                    element={
                        <QueryClientProvider client={queryClient}>
                            <AnalyticsProvider>
                                <Analytics
                                    isMobileScreenSize={isMobileScreenSize}
                                    mobileLiteMode={mobileliteMode}
                                    referentData={referentAccountData}
                                    setReferentData={setReferentAccountData}
                                    currentChildSelected={currentChildSelected}
                                    setCurrentChildSelected={setCurrentChildSelected}
                                />
                            </AnalyticsProvider>
                        </QueryClientProvider>
                    }
                />
                <Route path="/settings" element={<Settings/>}/>
                <Route
                    path="/accountValidation/:token/:referentId/:email/:childId/:username"
                    element={<AccountValidation/>}
                />
                <Route path="forgotPassword" element={<ForgotPassword/>}/>
                <Route
                    path="/resetPassword/:token/:email/:username?"
                    element={<ReinitPassword/>}
                />
                <Route
                    path="/resendParentConfigMail"
                    element={<ResendParentConfigMail/>}
                />
                <Route path="ImageEditor" element={<ImageEditor/>}/>
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Routes>
            {!hideHeaderFooter && (isPublicPath ? <FooterPublic/> : <FooterAnalytics/>)}
        </div>
    );
}

export default App;
