import React, { useEffect, useState } from "react";

import "./profile-selector-pc.scss";

import TestAvatar from "../../../resources/images/wytopia-icon-02.png";
import disconnectIcon from "../../../resources/images/icons/sortie_blanc.png";
import checkIcon from "../../../resources/images/icons/check_blanc.png";
import arrowLeftIcon from "../../../resources/images/icons/gauche_blanc.png";
import arrowRightIcon from "../../../resources/images/icons/droite_blanc.png";
import { ProfileSelectorPcProps } from "../../../interfaces/analytics-interfaces/analytics-interface";
import { findChildIndex } from "../../../helper/common-helpers/params-helper";
import Cookies from "js-cookie";

const ProfileSelectorPc: React.FC<ProfileSelectorPcProps> = (
  props: ProfileSelectorPcProps
) => {
  const initialIndex = props?.referentData?.children
    ? findChildIndex(
          props.currentChildSelected?.childId ?? 0,
        props.referentData?.children
      )
    : 0;

  const [currentChildIndex, setCurrentChildIndex] = useState(initialIndex);

  const switchChild = (direction: "left" | "right") => {
    if (
      !props.referentData?.children ||
      props.referentData.children.length === 0
    )
      return;

    const children = props.referentData.children;

    let newIndex = currentChildIndex;

    if (direction === "left") {
      newIndex = newIndex === 0 ? children.length - 1 : newIndex - 1;
    } else {
      newIndex = (newIndex + 1) % children.length;
    }

    Cookies.remove("appChildId");
    setCurrentChildIndex(newIndex);
    props.setCurrentChildSelected(children[newIndex]);

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("childId");
    const newQueryString = urlParams.toString();
    const newUrl = newQueryString
        ? `${window.location.pathname}?${newQueryString}`
        : window.location.pathname;

    window.history.replaceState(null, "", newUrl);
  };

  useEffect(() => {
    const newIndex = props.referentData?.children
      ? findChildIndex(
            props.currentChildSelected?.childId ?? 0,
          props.referentData?.children
        )
      : 0;
    setCurrentChildIndex(newIndex);
  }, [props.currentChildSelected, props.referentData]);

  const currentAvatar = props.avatars.find(
      (avatar) => avatar.avatarID === Number(props.currentChildSelected?.avatarId)
  );
  
  return (
    <div className="profile-selector-pc">
      <div className="top-section">
        <img
          src={arrowLeftIcon}
          onClick={() => switchChild("left")}
          alt="Arrow Left"
          className="arrow-icon"
        />
        <img
            src={currentAvatar?.path || TestAvatar}
            alt="User Avatar"
            className="avatar"
        />
        <img
          src={arrowRightIcon}
          onClick={() => switchChild("right")}
          alt="Arrow Right"
          className="arrow-icon"
        />
      </div>

      <div className="mid-section">
        <div className="profile-username">{props.childName?.toUpperCase()}</div>
      </div>

      <div className="bottom-section">
        <div className="status">
          <img src={checkIcon} alt="Tick Icon" className="tick-icon" />
          <span>Connecté</span>
        </div>
        <button className="logout-btn" onClick={props.handleLogout}>
          <img src={disconnectIcon} alt="Logout Icon" />
        </button>
      </div>
    </div>
  );
};

export default ProfileSelectorPc;