import React, {useEffect, useState, useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "../analytics-context";
import "./settings-analytics.scss";
import DetailCard from "../../../components/detailCard/detail-card";
import {
    AlertIconDetails,
    LockIconDetails,
    ScreenTimeIconDetails,
    SleepModeIconDetails,
} from "../../../constants/settings-analytics-constants";
import SettingsNotifications from "./settings-notifications";
import SettingsSecurity from "./settings-security";
import SettingsSleepMode from "./settings-sleep-mode";
import SettingsScreenTime from "./settings-screen-time";
import {RotatingTriangles} from "react-loader-spinner";
import {SettingsAnalyticsProps} from "../../../interfaces/settings-interfaces/settings-analytics-interface";
import {ReferentBaseModel} from "../../../interfaces/referent-interfaces/parents-interface";
import isEqual from "lodash/isEqual";
import {ChildMaximumScreenTimeModel, ChildSleepModeModel} from "../../../interfaces/child-interfaces/child-interface";
import {handleSubmit} from "./utils/settingsUtils";

const SettingsAnalytics: React.FC<SettingsAnalyticsProps> = ({
                                                                 currentChildSelected,
                                                                 referentData,
                                                                 setReferentData,
                                                             }) => {
    const {t} = useTranslation();
    const {isLoading, error, referentAccountData, fetchReferentAccountData} = useAnalytics();

    const [formData, setFormData] = useState<ReferentBaseModel | null>(referentData);
    const [initialFormData, setInitialFormData] = useState<ReferentBaseModel | null>(referentData);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [isPrimaryLoading, setIsPrimaryLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setIsPrimaryLoading(false), 150);
        return () => clearTimeout(timer);
    }, []);
    
    useEffect(() => {
        if (referentData) {
            setFormData(JSON.parse(JSON.stringify(referentData)));
        }
    }, [referentData]);

    useEffect(() => {
        if (formData && initialFormData) {
            setIsModified(!isEqual(formData, initialFormData));
        }
    }, [formData, initialFormData]);

    // Memoize currentChildData to prevent unnecessary re-renders
    const currentChildData = useMemo(() => {
        return formData?.children?.find(
            (child) => child.childId === currentChildSelected.childId
        );
    }, [formData, currentChildSelected.childId]);

    // Fetch referent account data if it’s not already loaded
    useEffect(() => {
        if (!referentAccountData?.object && referentData?.referentId && referentData.token) {
            fetchReferentAccountData(referentData.referentId, referentData.token);
        }
    }, [referentAccountData?.object, referentData, fetchReferentAccountData]);

    // Set initial form data when referent account data is fetched
    useEffect(() => {
        if (referentAccountData?.object) {
            setFormData(referentAccountData.object);
            setInitialFormData(referentAccountData.object);
        }
    }, [referentAccountData]);

    const updateFormData = useCallback(
        (key: keyof ReferentBaseModel, value: any) => {
            setFormData((prev) => {
                if (prev) {
                    const updatedData = {...prev, [key]: value};
                    setIsModified(!isEqual(updatedData, initialFormData));
                    return updatedData;
                }
                return prev;
            });
        },
        [initialFormData]
    );

    const updateScreenTime = useCallback(
        (childId: number, newScreenTimes: ChildMaximumScreenTimeModel[]) => {
            setFormData((prev) => {
                if (!prev) return prev;

                const updatedChildren = prev.children.map((child) =>
                    child.childId === childId
                        ? {...child, access: {...child.access, maximumScreenTimes: newScreenTimes}}
                        : child
                );

                const updatedFormData = {...prev, children: updatedChildren};

                setIsModified(true);

                return updatedFormData;
            });
        },
        []
    );

    const updateSleepMode = useCallback(
        (childId: number, newSleepModes: ChildSleepModeModel[]) => {
            setFormData((prev) => {
                if (!prev) return prev;

                const updatedChildren = prev.children.map((child) =>
                    child.childId === childId
                        ? {
                            ...child,
                            access: {
                                ...child.access,
                                sleepModes: newSleepModes,
                            },
                        }
                        : child
                );

                const updatedFormData = { ...prev, children: updatedChildren };

                setIsModified(true);

                return updatedFormData;
            });
        },
        [setFormData, setIsModified]
    );

    const updateEmailNotifications = useCallback(
        (isActive: boolean) => {
            setFormData((prev) => {
                if (!prev) return prev;

                const updatedFormData = {...prev, emailNotifications: isActive};

                setIsModified(!isEqual(updatedFormData, initialFormData));

                return updatedFormData;
            });
        },
        [initialFormData]
    );

    const updateParentPassword = useCallback(
        (newPassword: string) => {
            setFormData((prev) => {
                if (!prev) return prev;
                const updatedFormData = {...prev, password: newPassword};
                setIsModified(!isEqual(updatedFormData, initialFormData));
                return updatedFormData;
            });
        },
        [initialFormData]
    );

    // auto-save changes after 3 seconds of any changes
    useEffect(() => {
        if (isModified) {
            const timer = setTimeout(() => {
                handleSave();
            }, 3000);
            return () => clearTimeout(timer); // Reset timer
        }
    }, [formData, isModified]);

    const handleSave = async () => {
        try {
            if (!formData) return;
            const { password, ...dataToSave } = formData;
            const success = await handleSubmit(
                dataToSave,
                initialFormData,
                referentData,
                (updatedData) => {
                    setReferentData(updatedData);
                    setFormData({ ...updatedData });
                    setInitialFormData({ ...updatedData });
                }
            );

            if (success) {
                setIsModified(false);
            }
        } catch (error) {
            console.error("Error while updating settings:", error);
        }
    };

    return (
        <>
            {error && <p style={{color: "red"}}>Error: {error.message}</p>}
            {isLoading || isPrimaryLoading ? (
                <div style={{alignSelf: "center"}}>
                    <RotatingTriangles
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="rotating-triangles-loading"
                        wrapperClass="rotating-triangles-wrapper"
                        colors={["#018cd5", "#de2781", "#f19700"]}
                    />
                </div>
            ) : (
                <>
                    <DetailCard
                        hasHeader
                        headerTitle={t("analyticsPages.settings_page.card_max_screen_time")}
                        iconLeft={[ScreenTimeIconDetails]}
                        themeColor="#823d90"
                    >
                        <SettingsScreenTime
                            updateScreenTime={updateScreenTime}
                            currentChildData={currentChildData}
                        />
                    </DetailCard>
                    <DetailCard
                        hasHeader
                        headerTitle={t("analyticsPages.settings_page.card_sleep_mode")}
                        iconLeft={[SleepModeIconDetails]}
                        themeColor="#823d90"
                    >
                        <SettingsSleepMode
                            updateSleepMode={updateSleepMode}
                            currentChildData={currentChildData}
                        />
                    </DetailCard>
                    <DetailCard
                        hasHeader
                        headerTitle={t("analyticsPages.settings_page.card_notifications")}
                        iconLeft={[AlertIconDetails]}
                        themeColor="#823d90"
                    >
                        <SettingsNotifications
                            receiveEventEmails={formData?.emailNotifications || false}
                            updateNotificationPreference={updateEmailNotifications}
                        />
                    </DetailCard>
                    <DetailCard
                        hasHeader
                        headerTitle={t("analyticsPages.settings_page.card_security")}
                        iconLeft={[LockIconDetails]}
                        themeColor="#823d90"
                    >
                        <SettingsSecurity
                            updateSecurity={(key, value) => updateFormData(key, value)}
                            updateParentPassword={updateParentPassword}
                            referentData={formData}
                            setReferentData={setFormData}
                        />
                    </DetailCard>
                </>
            )}
        </>
    );
};

export default SettingsAnalytics;
