import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./summary.scss";

import { ChildBaseModel } from "../../../interfaces/child-interfaces/child-interface";
import { useScreenTimeData } from "../../../hooks/use-screen-time-data";
import { getLanguageNumber } from "../../../helper/common-helpers/language-helper";
import DetailCard from "../../../components/detailCard/detail-card";
import {
  ActivitiesIconVioletDetails,
  HistoryIconVioletDetails,
  ProgressIconVioletDetails,
  RecommendationsIconVioletDetails,
  ScreenTimeIconVioletDetails,
  SearchIconVioletDetails,
  SkillMapIconVioletDetails,
} from "../../../constants/analytics-constants";
import AdvancedProgressBar from "../../../components/progress-bar/advanced-progress-bar";
import {
  calculateTotalPoints,
  getTodayMetrics,
} from "../../../helper/analytics-helpers/summary-helpers";
import AnalyticsLoading from "../../../components/analytics/analytics-loading";
import { useHistoryData } from "../../../hooks/use-history-data";
import { useAnalytics } from "../analytics-context";
import { getGameVsMediaProgress } from "../../../helper/analytics-helpers/activities-helper";
import {
  CategoryVideoIconDetails,
  CategoryGameIconDetails,
} from "../../../constants/recommendation-constants";
import { useSkillProgressData } from "../../../hooks/use-skill-progress-data";
import { updateSkillHistoryThemeColor } from "../../../helper/analytics-helpers/history-helper";
import { useRecommendationData } from "../../../hooks/use-recommendation-data";
import {
  StarBlueDetails,
  StarRoseDetails,
  StarYellowDetails,
} from "../../../constants/skill-map-constants";
import { useSkillProgressPeriodData } from "../../../hooks/use-skill-progress-period-data";
import ProgressSkillTable from "../../../components/analytics/summary/progress-skill-table";
import { useNavigate } from "react-router";
import HistoryItem from "../../../components/analytics/summary/history-item";

interface SummaryProps {
  currentChildSelected: ChildBaseModel | null;
}

const Summary: React.FC<SummaryProps> = (props: SummaryProps) => {
  const { t, i18n } = useTranslation();
  const actualLanguage = i18n.language;
  const languageNumber = getLanguageNumber(actualLanguage);
  const { isLoading, contentStatisticsData, fetchContentStatistics } =
    useAnalytics();

  const DEFAULT_MAX_SESSION_TIME = 60; // 60 minutes

  const [todaySessionTime, setTodaySessionTime] = useState<number>(0);
  const [todayVisits, setTodayVisits] = useState<number>(0);
  
  const [gameVsMediaProgress, setGameVsMediaProgress] = useState<{
    gamePercentage: number;
    mediaPercentage: number;
  }>({ gamePercentage: 0, mediaPercentage: 0 });
  const { gamePercentage, mediaPercentage } = gameVsMediaProgress;
  
  const { isProcessingScreenTimeData, screenTimeData } = useScreenTimeData({
    childrenData: props.currentChildSelected,
    actualLanguage: languageNumber,
    dateLimit: "perDay",
  });

  const { isProcessingHistoryData, historyData } = useHistoryData({
    childrenData: props.currentChildSelected,
    actualLanguage: languageNumber,
    dateLimit: "year",
  });

  const { isProcessingSkillProgressData, skillLevel1ProgressData } =
    useSkillProgressData({
      childrenData: props.currentChildSelected,
      actualLanguage: languageNumber,
    });

  const { isProcessingRecommendationData, skillsToWorkOn } =
    useRecommendationData({
      childrenData: props.currentChildSelected,
      actualLanguage: languageNumber,
    });

  const {
    isProcessingSkillProgressPeriodData: isProcessingWeek,
    skillProgressPeriodData: weekData,
  } = useSkillProgressPeriodData({
    childrenData: props.currentChildSelected,
    dateLimit: "last12Days",
  });

  const {
    isProcessingSkillProgressPeriodData: isProcessingMonth,
    skillProgressPeriodData: monthData,
  } = useSkillProgressPeriodData({
    childrenData: props.currentChildSelected,
    dateLimit: "last12Weeks",
  });

  const {
    isProcessingSkillProgressPeriodData: isProcessingYear,
    skillProgressPeriodData: yearData,
  } = useSkillProgressPeriodData({
    childrenData: props.currentChildSelected,
    dateLimit: "last12Months",
  });

  const weekTotalPoints = calculateTotalPoints(weekData, 7); // last 7 days (1week)
  const monthTotalPoints = calculateTotalPoints(monthData, 4); // last 4 weeks (1month)
  const yearTotalPoints = calculateTotalPoints(yearData, 12); // last 12 months (1year)

  useEffect(() => {
    fetchContentStatistics();
  }, []);

  useEffect(() => {
    const { todaySessionTime, todaySessions } = getTodayMetrics(screenTimeData);
    setTodaySessionTime(todaySessionTime);
    setTodayVisits(todaySessions);
  }, [screenTimeData]);

  useEffect(() => {
    if (historyData.length > 0) {
      const gameProgress = getGameVsMediaProgress(historyData);
      setGameVsMediaProgress(gameProgress);
    }
  }, [historyData]);

  const navigate = useNavigate();
  const handleIconClickNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <>
      {/* screen time  */}
      <div className="analytics__stats-content--card">
        <DetailCard
          hasHeader={true}
          headerTitle={t("analyticsPages.summary_page.screen_time_title")}
          themeColor="#823d90"
          iconLeft={[ScreenTimeIconVioletDetails]}
          iconRight={[
            {
              ...SearchIconVioletDetails,
              onClick: () => handleIconClickNavigate("/analytics/screen-time"),
            },
          ]}
        >
          {isProcessingScreenTimeData ? (
            <AnalyticsLoading size="medium" />
          ) : (
            <AdvancedProgressBar
              styleOverride={{ margin: "12px 0 24px 0" }}
              completed={todaySessionTime}
              barSize="small"
              enableDynamicColor={true}
              customMaxValue={DEFAULT_MAX_SESSION_TIME}
              infoTopLeft={
                <p className="summary__screen-time__info-top-left">
                  {todaySessionTime} {t("analyticsPages.summary_page.minutes")}
                </p>
              }
              infoBottomLeft={
                <p>
                  {todayVisits} {t("analyticsPages.summary_page.visits")}
                </p>
              }
              infoBottomRight={
                <p>
                  {t("analyticsPages.summary_page.limit")}{" "}
                  {DEFAULT_MAX_SESSION_TIME}{" "}
                  {t("analyticsPages.summary_page.minutes_short")}
                </p>
              }
            />
          )}
        </DetailCard>
      </div>

      {/* history */}
      <div className="analytics__stats-content--card">
        <DetailCard
          hasHeader={true}
          headerTitle={t("analyticsPages.summary_page.history")}
          themeColor="#823d90"
          iconLeft={[HistoryIconVioletDetails]}
          iconRight={[
            {
              ...SearchIconVioletDetails,
              onClick: () => handleIconClickNavigate("/analytics/history"),
            },
          ]}
        >
          {isProcessingHistoryData ? (
            <AnalyticsLoading size="medium" />
          ) : (
            <>
              <div className="summary__history">
                {historyData.slice(0, 7).map((data, index) => (
                  <HistoryItem
                    key={index}
                    thumbnailSrc={data.thumbnail}
                    category={data.activityFormat}
                    title={data.activityTitle}
                  />
                ))}
              </div>
            </>
          )}
        </DetailCard>
      </div>

      {/* activities */}
      <div className="analytics__stats-content--card">
        <DetailCard
          hasHeader={true}
          headerTitle={t("analyticsPages.summary_page.activities")}
          themeColor="#823d90"
          iconLeft={[ActivitiesIconVioletDetails]}
          iconRight={[
            {
              ...SearchIconVioletDetails,
              onClick: () => handleIconClickNavigate("/analytics/activities"),
            },
          ]}
        >
          {isLoading || isProcessingHistoryData ? (
            <AnalyticsLoading size="medium" />
          ) : (
            <AdvancedProgressBar
              styleOverride={{ margin: "12px 0 12px 0" }}
              completed={Number(mediaPercentage?.toFixed(2))}
              barSize="small"
              leftbgcolor="#e84320"
              rightbgcolor="#345fab"
              bottomLeftTextColor="#e84320"
              bottomRightTextColor="#345fab"
              worldMedian={
                contentStatisticsData.object[0].mediaVsGamePlayedMed ||
                contentStatisticsData.object[0].mediaVsGamePlayedAvg
              }
              infoBottomLeft={
                <div className="summary__activities__info-bot">
                  <img
                    src={CategoryVideoIconDetails.src}
                    alt={CategoryVideoIconDetails.alt}
                  />
                  <p>
                    {t(`analyticsPages.activities_page.video`)}
                    {Math.round(mediaPercentage)}%
                  </p>
                </div>
              }
              infoBottomRight={
                <div className="summary__activities__info-bot">
                  <img
                    src={CategoryGameIconDetails.src}
                    alt={CategoryGameIconDetails.alt}
                  />
                  <p>
                    {t(`analyticsPages.activities_page.game`)}
                    {Math.round(gamePercentage)}%
                  </p>
                </div>
              }
            />
          )}
        </DetailCard>
      </div>

      {/* skill map */}
      <div className="analytics__stats-content--card">
        <DetailCard
          hasHeader={true}
          headerTitle={t("analyticsPages.summary_page.skill_map")}
          themeColor="#823d90"
          iconLeft={[SkillMapIconVioletDetails]}
          iconRight={[
            {
              ...SearchIconVioletDetails,
              onClick: () => handleIconClickNavigate("/analytics/skill-map"),
            },
          ]}
        >
          {isProcessingSkillProgressData ? (
            <AnalyticsLoading size="medium" />
          ) : skillLevel1ProgressData && skillLevel1ProgressData.length > 0 ? (
            skillLevel1ProgressData.map((data, index) => {
              const skillColor = updateSkillHistoryThemeColor(data.id);
              return (
                <AdvancedProgressBar
                  key={index}
                  styleOverride={{ margin: "0 0 6px 0" }}
                  leftbgcolor={skillColor}
                  completed={data.progress}
                  barSize="small"
                  infoTopLeft={
                    <p className="summary__skill-map__info-top-left">
                      <img
                        src={
                          skillColor === "#59c0d1"
                            ? StarBlueDetails.src
                            : skillColor === "#e8318b"
                            ? StarRoseDetails.src
                            : StarYellowDetails.src
                        }
                        alt={data.name}
                      />{" "}
                      {data.name.replace(/^\d+-\s/, "")} (
                      {Math.round(data.progress)}
                      %)
                    </p>
                  }
                />
              );
            })
          ) : (
            <p>No data available</p>
          )}
        </DetailCard>
      </div>

      {/* progress */}
      <div className="analytics__stats-content--card">
        <DetailCard
          hasHeader={true}
          headerTitle={t("analyticsPages.summary_page.progress")}
          themeColor="#823d90"
          iconLeft={[ProgressIconVioletDetails]}
          iconRight={[
            {
              ...SearchIconVioletDetails,
              onClick: () => handleIconClickNavigate("/analytics/progress"),
            },
          ]}
        >
          {isProcessingWeek || isProcessingMonth || isProcessingYear ? (
            <AnalyticsLoading size="medium" />
          ) : (
            <>
              <ProgressSkillTable
                totalPointsWeek={weekTotalPoints}
                totalPointsMonth={monthTotalPoints}
                totalPointsYear={yearTotalPoints}
              />
            </>
          )}
        </DetailCard>
      </div>

      {/* recommendations */}
      <div className="analytics__stats-content--card">
        <DetailCard
          hasHeader={true}
          headerTitle={t("analyticsPages.summary_page.recommendations")}
          themeColor="#823d90"
          iconLeft={[RecommendationsIconVioletDetails]}
          iconRight={[
            {
              ...SearchIconVioletDetails,
              onClick: () =>
                handleIconClickNavigate("/analytics/recommendations"),
            },
          ]}
        >
          {isProcessingRecommendationData ? (
            <AnalyticsLoading size="medium" />
          ) : (
            <>
              <p className="summary__recommendation__subtitle">
                Compétences prioritaires :
              </p>
              {skillsToWorkOn.slice(0, 3).map((skill, index) => {
                const starColor = updateSkillHistoryThemeColor(skill.parentId);

                return (
                  <div className="summary__recommendation" key={index}>
                    <img
                      src={
                        starColor === "#59c0d1"
                          ? StarBlueDetails.src
                          : starColor === "#e8318b"
                          ? StarRoseDetails.src
                          : StarYellowDetails.src
                      }
                      alt={skill.name}
                    />
                    <p>{skill.name}</p>
                  </div>
                );
              })}
            </>
          )}
        </DetailCard>
      </div>
    </>
  );
};

export default Summary;
