import React, {createContext, useEffect, useState} from "react";
import {ReferentBaseModel} from "../../interfaces/referent-interfaces/parents-interface";
import {fetchReferentAccountDetailsApi} from "../../helper/apiHelper/parent-api";
import Cookies from "js-cookie";
import {removeAuthCookies} from "../../helper/auth-helpers/cookie-helper";

interface AuthContextProps {
    isAuthenticated: boolean;
    referentData: ReferentBaseModel | null;
    setReferentData: (data: ReferentBaseModel) => void;
    setIsAuthenticated: (value: boolean) => void;
    updateReferentData: (data: ReferentBaseModel) => void;
    isInitializing: boolean;
}

export const AuthContext = createContext<AuthContextProps>({
    isAuthenticated: false,
    referentData: null,
    setReferentData: () => {},
    setIsAuthenticated: () => {},
    updateReferentData: () => {},
    isInitializing: true,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [referentData, setReferentData] = useState<ReferentBaseModel | null>(null);
    const [isInitializing, setIsInitializing] = useState(true);

    useEffect(() => {
        const token = Cookies.get("tokenSession");
        const referentId = parseInt(Cookies.get("referentID") || "0", 10);
        if (token && referentId) {
            fetchReferentData(referentId, token);
        } else {
            setIsInitializing(false);
        }
    }, []);

    const fetchReferentData = async (referentId: number, token: string) => {
        try {
            const response = await fetchReferentAccountDetailsApi({ referentId, token });
            if (response && response.object) {
                setReferentData(response.object as ReferentBaseModel);
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                removeAuthCookies();
            }
        } catch (error) {
            setIsAuthenticated(false);
            removeAuthCookies();
        } finally {
            setIsInitializing(false);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                referentData,
                setReferentData,
                setIsAuthenticated,
                updateReferentData: setReferentData,
                isInitializing,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
