import axios from "axios";
import isEqual from "lodash/isEqual";
import Cookies from "js-cookie";
import { ReferentBaseModel } from "../../../../interfaces/referent-interfaces/parents-interface";
import URLS from "../../../../config/api-urls";

/**
 * Updates the parent information, including password.
 */
export const updateParent = async (formData: ReferentBaseModel, referentData: ReferentBaseModel) => {
    const parentPayload = {
        email: formData.email,
        password: formData.password,
        token: referentData.token,
    };
    
    try {
        const response = await axios.post(URLS.POST_UpdateParent, parentPayload, {
            headers: { "Content-Type": "application/json" },
        });

        if (response.data && response.data.statusMessage.includes("success")) {
            Cookies.set("tokenSession", response.data.object.token);
            return response.data.object;
        }

        throw new Error("Failed to update parent data");
    } catch (error) {
        throw error;
    }
};


/**
 * Updates a child's settings such as sleep modes and screen time.
 */
export const updateChild = async (child) => {
    const childPayload = {
        ChildId: child.childId,
        UserName: child.userName,
        Gender: child.gender,
        Email: child.email,
        DateofBirth: child.dateofBirth,
        Country: child.country,
        IsActive: child.isActive,
        IsDelete: child.isDelete,
        AvatarId: child.avatarId,
        Token: child.token,
        Access: child.access,
    };
    
    try {
        const response = await axios.post(URLS.POST_UpdateChild, childPayload, {
            headers: { "Content-Type": "application/json" },
        });
        
        if (response.data && response.data.statusMessage.includes("success")) {
            return response.data.object; // Return updated child data
        }

        throw new Error(`Failed to update child data for childId ${child.childId}`);
    } catch (error) {
        throw error;
    }
};


/**
 * Handles the full submission process for updating parent and child settings.
 */
export const handleSubmit = async (
    formData: ReferentBaseModel,
    initialFormData: ReferentBaseModel,
    referentData: ReferentBaseModel,
    updateReferentData: (data: ReferentBaseModel) => void
) => {
    try {
        let updatedReferentData = { ...referentData };
        let referentDataChanged = false;

        // Handle parent updates (e.g., password change)
        if (formData.password && formData.password !== initialFormData.password) {
            const updatedParentData = await updateParent(formData, referentData);
            updatedReferentData = { ...updatedReferentData, ...updatedParentData };
            referentDataChanged = true;
        }

        // Handle child updates (e.g., sleep modes, screen time)
        const childUpdatePromises = formData.children.map((child) => {
            const initialChild = initialFormData.children.find((c) => c.childId === child.childId);

            if (
                !isEqual(child.access.maximumScreenTimes, initialChild.access.maximumScreenTimes) ||
                !isEqual(child.access.sleepModes, initialChild.access.sleepModes)
            ) {
                return updateChild(child);
            }

            return null;
        });

        await Promise.all(childUpdatePromises.filter(Boolean));

        // Update children in referentData with data from formData
        formData.children.forEach((updatedChild) => {
            const childIndex = updatedReferentData.children.findIndex(
                (c) => c.childId === updatedChild.childId
            );

            if (childIndex !== -1) {
                updatedReferentData.children[childIndex] = updatedChild;
            }
        });

        // Update referentData context if there are any changes
        if (referentDataChanged || childUpdatePromises.filter(Boolean).length > 0) {
            updateReferentData(updatedReferentData);
        }

        return true; // Success
    } catch (error) {
        throw error;
    }
};

