import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./settings-screen-time.scss";
import { ChildMaximumScreenTimeModel } from "../../../interfaces/child-interfaces/child-interface";
import { SettingsScreenTimeProps } from "../../../interfaces/settings-interfaces/settings-analytics-interface";

const SettingsScreenTime: React.FC<SettingsScreenTimeProps> = ({ currentChildData, updateScreenTime }) => {
    const { t } = useTranslation();
    const [screenTimes, setScreenTimes] = useState<ChildMaximumScreenTimeModel[]>([]);

    // Initialize screen times based on the current child's data
    useEffect(() => {
        if (currentChildData?.access?.maximumScreenTimes) {
            const defaultScreenTimes: ChildMaximumScreenTimeModel[] = Array.from({ length: 7 }, (_, i) => ({
                childId: currentChildData.childId,
                dayOfWeek: i + 1, // 1 = Monday, 7 = Sunday
                dailyLimitMinutes: 0, // Default limit
            }));

            const normalizedScreenTimes = defaultScreenTimes.map((defaultDay) => {
                const existingDay = currentChildData.access.maximumScreenTimes.find(
                    (item) => item.dayOfWeek === defaultDay.dayOfWeek
                );
                return existingDay || defaultDay; // Use existing data or default
            });

            setScreenTimes(normalizedScreenTimes);
        }
    }, [currentChildData]);

    // Function to change the daily limit for a specific day
    const changeDailyLimit = (dayOfWeek: number, deltaMinutes: number) => {
        const updatedScreenTimes = screenTimes.map((item) =>
            item.dayOfWeek === dayOfWeek
                ? { ...item, dailyLimitMinutes: Math.max(0, item.dailyLimitMinutes + deltaMinutes) }
                : item
        );
        setScreenTimes(updatedScreenTimes);

        if (currentChildData) {
            updateScreenTime(currentChildData.childId, updatedScreenTimes);
        }
    };

    // Format time in hours and minutes
    const formatTime = (minutes: number): string => {
        if (minutes < 60) return `${minutes}m`;
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return remainingMinutes > 0 ? `${hours}h${remainingMinutes}` : `${hours}h`;
    };

    const dayNames = [
        t("analyticsPages.settings_page.day_monday"),
        t("analyticsPages.settings_page.day_tuesday"),
        t("analyticsPages.settings_page.day_wednesday"),
        t("analyticsPages.settings_page.day_thursday"),
        t("analyticsPages.settings_page.day_friday"),
        t("analyticsPages.settings_page.day_saturday"),
        t("analyticsPages.settings_page.day_sunday"),
    ];

    // Function to set all daily limits to a specific value
    const setAllDailyLimits = useCallback(
        (minutes: number) => {
            const updatedScreenTimes = screenTimes.map((item) => ({
                ...item,
                dailyLimitMinutes: minutes,
            }));
            setScreenTimes(updatedScreenTimes);

            if (currentChildData) {
                updateScreenTime(currentChildData.childId, updatedScreenTimes);
            }
        },
        [screenTimes, currentChildData, updateScreenTime]
    );

    // Function to set advised time based on the child's age
    const setAdvisedTime = useCallback(() => {
        let advisedMinutes = 0;
        if (currentChildData?.dateofBirth) {
            const birthDate = new Date(currentChildData.dateofBirth);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            if (age >= 2 && age < 5) {
                advisedMinutes = 60; // 1 hour/day
            } else if (age >= 5 && age < 10) {
                advisedMinutes = 120; // 2 hours/day
            } else {
                advisedMinutes = 180; // Default for other ages
            }
        } else {
            advisedMinutes = 120; // Default advised time if age is unavailable
        }

        setAllDailyLimits(advisedMinutes);
    }, [currentChildData, setAllDailyLimits]);

    return (
        <div className="settings-screen-time">
            <div className="explanatory-text">
                <span>{t("analyticsPages.settings_page.screen_time_recommendation_intro")}</span>
                <span>{t("analyticsPages.settings_page.screen_time_recommendation_1")}</span>
                <span>{t("analyticsPages.settings_page.screen_time_recommendation_2")}</span>
            </div>


            <div className="preset-settings">
                <div className="preset-text">
                    <span>{t("analyticsPages.settings_page.shortcuts_text")}</span>
                </div>
                
                <div className="preset-buttons">
                <button className="preset-button" onClick={setAdvisedTime}>
                    {t("analyticsPages.settings_page.advised_time_button")}
                </button>
                <button className="preset-button" onClick={() => setAllDailyLimits(60)}>
                    1h
                </button>
                <button className="preset-button" onClick={() => setAllDailyLimits(120)}>
                    2h
                </button>
                <button className="preset-button" onClick={() => setAllDailyLimits(240)}>
                    4h
                </button>
                <button className="preset-button" onClick={() => setAllDailyLimits(480)}>
                    8h
                </button>
                </div>
            </div>

            <div className="child-screen-time">
                <div className="screen-time-schedule">


                    <div className="day-names">
                        {dayNames.map((day, index) => (
                            <div key={index} className="day-name">
                                {day}
                            </div>
                        ))}
                    </div>
                    <div className="daily-limits">
                        {screenTimes.map((dayLimit) => (
                            <div key={dayLimit.dayOfWeek} className="day-limit">
                                <button
                                    className="adjust-button"
                                    onClick={() => changeDailyLimit(dayLimit.dayOfWeek, 15)}
                                >
                                    +
                                </button>
                                <div className="limit-circle">
                                    {formatTime(dayLimit.dailyLimitMinutes || 0)}
                                </div>
                                <button
                                    className="adjust-button"
                                    onClick={() => changeDailyLimit(dayLimit.dayOfWeek, -15)}
                                >
                                    -
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsScreenTime;
