import React, {useState, useEffect, useContext} from "react";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";

import "./login.scss";

import URLS from "../../config/api-urls";
import {
    setAuthCookies, setMailCookies,
} from "../../helper/auth-helpers/cookie-helper";
import {
    ResponseData,
} from "../../interfaces/auth-interfaces/auth-interface";
import {AuthContext} from "./auth-context";
import {TailSpin} from "react-loader-spinner";
import {EyeIconDetails} from "../../constants/auth-constants";

function Login() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailParam = urlParams.get("parentEmail");
    const childId = urlParams.get("childId");
    const lang = urlParams.get("lang");
    const authContext = useContext(AuthContext);
    
    const [emailFromUrl, setEmailFromUrl] = useState<string>(Cookies.get("lastEmailUsed") || "");
    const [responseData, setResponseData] = useState<ResponseData | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [defaultEmail, setDefaultEmail] = useState("");

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
    } = useForm();

    useEffect(() => {
        if (lang) {
            Cookies.set("appLangId", lang, { expires: 7, secure: true });

            const langMap = { "0": "en", "1": "fr", "2": "de" };
            const selectedLang = langMap[lang] || "en";
            i18n.changeLanguage(selectedLang);
        }
        if (emailParam) {
            setEmailFromUrl(emailParam);
            setValue("username", emailParam);
            Cookies.set("parentEmail", emailParam, {expires: 7, secure: true});
        }
        if (childId) {
            Cookies.set("appChildId", childId, { expires: 7, secure: true });
        }
    }, [location.search]);

    useEffect(() => {
        if (responseData) {
            if (responseData.data.statusMessage.includes("connection success")) {
                const { token, referentId } = responseData.data.object;
                setAuthCookies(token, referentId.toString());

                authContext.setIsAuthenticated(true);
                authContext.setReferentData(responseData.data.object);
                
                navigate(`/analytics?childId=${childId}`);
            } else {
                setIsLoading(false);
            }
        }
    }, [responseData, childId, authContext, navigate]);
    
    const formatDataForLogin = (data) => {
        const {username, password} = data;

        const formattedData = {
            email: username,
            password,
        };
        onSubmit(formattedData);
    };

    const handleInputFieldClick = (event) => {
        if (globalThis.vuplex) {
            globalThis.vuplex.postMessage({type: "inputFieldClicked"});
        }

        const inputElement = event.target;
        if (inputElement && inputElement.focus) {
            inputElement.focus();
        }
    };

    const onSubmit = async (data) => {
        setIsLoading(true);
        setError(null);
        setResponseData(null);
        setMailCookies(data.email);

        try {
            const response = await axios.post(URLS.POST_ConnectWeb, data, {
                headers: {"Content-Type": "application/json"},
            });
            setResponseData(response);
        } catch (err) {
            console.error("Erreur lors de la requête :", err);
            setError(err.message || "Une erreur inconnue est survenue.");
            setIsLoading(false);
        }
    };

    return (
        <div className="login-page">
            <div className="login-page__container">
                <h2>{t("loginPage.connection")}</h2>
                <div className="login-page__container-inputs">
                    <form onSubmit={handleSubmit(formatDataForLogin)}>
                        <label>{t("loginPage.parent_email")}</label>
                        <div className="login-page__inputs login-page__username-inputs">
                            <input
                                autoComplete="username"
                                defaultValue={emailFromUrl}
                                onClick={(e) => handleInputFieldClick(e)}
                                className={`login-page__input ${
                                    errors.username && "login-page__input--error"
                                }`}
                                {...register("username", {
                                    required: true,
                                    maxLength: 45,
                                    minLength: 4,
                                })}
                            />
                            <Link
                                className="action-label"
                                to="/resendParentConfigMail"
                                tabIndex={-1}>
                                {t("loginPage.resend_parent_configuration_email")}
                            </Link>
                        </div>

                        <label>{t("loginPage.parent_password")}</label>

                        <div className="login-page__inputs login-page__inputs--password">
                            <div className="login-page__input-container">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="current-password"
                                    onClick={(e) => handleInputFieldClick(e)}
                                    className={`login-page__input ${
                                        errors.password && "login-page__input--error"
                                    }`}
                                    {...register("password", {
                                        required: true,
                                        maxLength: 32,
                                        minLength: 4,
                                    })}
                                />
                                <img
                                    src={EyeIconDetails.src}
                                    alt={EyeIconDetails.alt}
                                    className="password-toggle-icon"
                                    onClick={togglePasswordVisibility}
                                />
                            </div>
                            <Link className="action-label" to="/forgotPassword" tabIndex={-1}>
                                {t("loginPage.forgot_password")}
                            </Link>
                        </div>


                        {Object.keys(errors).length !== 0 && (
                            <p className="login-page__error-message">
                                {t("forms.error.incorrect_field")}
                            </p>
                        )}

                        {isLoading ? (
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", margin: "24px 0"}}>
                                <TailSpin
                                    visible={true}
                                    height={30}
                                    width={30}
                                    color="#8a2be2" // Violet
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                        ) : (
                            <input
                                type="submit"
                                value={t("loginPage.connection")}
                                className="login-page__submit-button"
                                disabled={isLoading}
                            />
                        )}


                    </form>

                    <div className="login-page__no-account">
                    <Link
                            className="action-label"
                            to="/signup"
                            tabIndex={-1}>
                            {t("loginPage.no_account")}
                        </Link>
                    </div>

                    {responseData &&
                    Object.keys(responseData).length !== 0 &&
                    !responseData?.data?.statusMessage.includes("connection success") ? (
                        <div className="login-page__submit-message">
                            <p>{responseData?.data?.statusMessage}</p>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default Login;
