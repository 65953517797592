import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./settings-sleep-mode.scss";
import { ChildSleepModeModel } from "../../../interfaces/child-interfaces/child-interface";
import { SettingsSleepModeProps } from "../../../interfaces/settings-interfaces/settings-analytics-interface";
import isEqual from "lodash/isEqual";

interface GroupedSleepMode {
    childId: number;
    daysOfWeek: number[];
    startTime: string;
    endTime: string;
}

const SettingsSleepMode: React.FC<SettingsSleepModeProps> = ({
                                                                 currentChildData,
                                                                 updateSleepMode,
                                                             }) => {
    const { t } = useTranslation();
    const [groupedSleepModes, setGroupedSleepModes] = useState<GroupedSleepMode[]>([]);
    const prevSleepModes = usePrevious(currentChildData?.access?.sleepModes);

    function usePrevious<T>(value: T): T | undefined {
        const ref = useRef<T>();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    }
    
    // Regroup days by start and end times
    useEffect(() => {
        if (
            currentChildData?.access?.sleepModes &&
            !isEqual(currentChildData.access.sleepModes, prevSleepModes)
        ) {
            const grouped = currentChildData.access.sleepModes.reduce<Record<string, GroupedSleepMode>>(
                (acc, sleepMode) => {
                    const key = `${sleepMode.startTime}-${sleepMode.endTime}`;
                    if (!acc[key]) {
                        acc[key] = {
                            childId: sleepMode.childId,
                            daysOfWeek: [sleepMode.dayOfWeek],
                            startTime: sleepMode.startTime,
                            endTime: sleepMode.endTime,
                        };
                    } else if (!acc[key].daysOfWeek.includes(sleepMode.dayOfWeek)) {
                        acc[key].daysOfWeek.push(sleepMode.dayOfWeek); // Avoid duplicate days
                    }
                    return acc;
                },
                {}
            );
            setGroupedSleepModes(Object.values(grouped));
        }
    }, [currentChildData?.access?.sleepModes, prevSleepModes]);

    const handleTimeChange = (
        index: number,
        type: "startTime" | "endTime",
        value: string
    ) => {
        const updatedSleepModes = groupedSleepModes.map((mode, idx) =>
            idx === index ? { ...mode, [type]: `${value}:00` } : mode
        );
        setGroupedSleepModes(updatedSleepModes);
        if (currentChildData) {
            updateSleepMode(currentChildData.childId, flattenSleepModes(updatedSleepModes));
        }
    };

    const toggleDayOfWeek = (index: number, dayOfWeek: number) => {
        const updatedSleepModes = groupedSleepModes.map((mode, idx) => {
            if (idx !== index) return mode;
            const daysOfWeek = mode.daysOfWeek.includes(dayOfWeek)
                ? mode.daysOfWeek.filter((day) => day !== dayOfWeek)
                : [...mode.daysOfWeek, dayOfWeek];
            return { ...mode, daysOfWeek };
        });
        setGroupedSleepModes(updatedSleepModes);
        if (currentChildData) {
            updateSleepMode(currentChildData.childId, flattenSleepModes(updatedSleepModes));
        }
    };

    const addSleepMode = () => {
        if (currentChildData) {
            setGroupedSleepModes((prev) => [
                ...prev,
                {
                    childId: currentChildData.childId,
                    daysOfWeek: [],
                    startTime: "00:00:00",
                    endTime: "00:00:00",
                },
            ]);
        }
    };

    const deleteSleepMode = (index: number) => {
        const updatedSleepModes = groupedSleepModes.filter((_, idx) => idx !== index);
        setGroupedSleepModes(updatedSleepModes);
        if (currentChildData) {
            updateSleepMode(currentChildData.childId, flattenSleepModes(updatedSleepModes));
        }
    };

    const flattenSleepModes = (grouped: GroupedSleepMode[]): ChildSleepModeModel[] => {
        const seen = new Set<string>(); // To avoid duplicates
        return grouped.flatMap((group) =>
            group.daysOfWeek.map((dayOfWeek) => {
                const key = `${group.childId}-${dayOfWeek}-${group.startTime}-${group.endTime}`;
                if (seen.has(key)) return null; // Skip duplicates
                seen.add(key);
                return {
                    childId: group.childId,
                    dayOfWeek,
                    startTime: group.startTime,
                    endTime: group.endTime,
                };
            }).filter(Boolean) as ChildSleepModeModel[]
        );
    };

    const dayNames = [
        t("analyticsPages.settings_page.day_monday"),
        t("analyticsPages.settings_page.day_tuesday"),
        t("analyticsPages.settings_page.day_wednesday"),
        t("analyticsPages.settings_page.day_thursday"),
        t("analyticsPages.settings_page.day_friday"),
        t("analyticsPages.settings_page.day_saturday"),
        t("analyticsPages.settings_page.day_sunday"),
    ];

    return (
        <div className="settings-sleep-mode">
            <div className="explanatory-text">
                <span>{t("analyticsPages.settings_page.sleep_mode_recommendation_intro")}</span>
            </div>
            <div className="child-sleep-mode">
                {groupedSleepModes.map((mode, index) => (
                    <div key={index} className="sleep-schedule">
                        <button
                            className="delete-button"
                            onClick={() => deleteSleepMode(index)}
                            title={t("analyticsPages.settings_page.delete_sleep_mode")}
                        >
                            ✖
                        </button>
                        <div className="time-selection">
                            <label>{t("analyticsPages.settings_page.start_time")}</label>
                            <input
                                type="time"
                                lang="fr-FR"
                                value={mode.startTime.slice(0, 5)}
                                onChange={(e) => handleTimeChange(index, "startTime", e.target.value)}
                            />
                            <label>{t("analyticsPages.settings_page.end_time")}</label>
                            <input
                                type="time"
                                lang="fr-FR"
                                value={mode.endTime.slice(0, 5)}
                                onChange={(e) => handleTimeChange(index, "endTime", e.target.value)}
                            />
                        </div>
                        <div className="day-selection">
                            {Array.from({ length: 7 }, (_, i) => i + 1).map((day) => (
                                <button
                                    key={day}
                                    className={mode.daysOfWeek.includes(day) ? "active" : ""}
                                    onClick={() => toggleDayOfWeek(index, day)}
                                    title={t(`analyticsPages.settings_page.day_${day}`)}
                                >
                                    {dayNames[day - 1]}
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
                <button className="add-button" onClick={addSleepMode}>
                    +
                </button>
            </div>
        </div>
    );
};

export default SettingsSleepMode;
