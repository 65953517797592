import {useEffect, useState} from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';

import "./analytics.scss";

import AnalyticsMenu from "../../components/analytics/analytics-menu";
import SkillMap from "./skillMap/skill-map";
import Recommendations from "./recommendations/recommendations";
import {menuItems} from "../../constants/analytics-constants";
import {
    getCurrentMenuData,
    getFormattedDate,
} from "../../helper/analytics-helpers/analytics-helper";
import {AnalyticsProps} from "../../interfaces/analytics-interfaces/analytics-interface";
import SearchAnalytics from "./search-analytics/search-analytics";
import SettingsAnalytics from "./settings-analytics/settings-analytics";
import {useAnalytics} from "./analytics-context";
import HistoryAnalytics from "./history/history-analytics";
import ActivitiesAnalytics from "./activities/activities-analytics";
import ScreenTime from "./screen-time/screen-time";
import Progression from "./progression/progress";
import Summary from "./summary/summary";
import Admin from "./admin/admin";
import Rewards from "./rewards/rewards";
import Cookies from "js-cookie";

function Analytics(props: AnalyticsProps) {
    const {t} = useTranslation();
    const location = useLocation();
    const {referentAccountData} = useAnalytics();
    const [dateFilter, setDateFilter] = useState<string>("week");
    const [dateProgressFilter, setDateProgressFilter] =
        useState<string>("last12Days");
    const dateFilterStates = ["today", "week", "month", "year"];
    const dateProgressFilterStates = [
        "last12Days",
        "last12Weeks",
        "last12Months",
        "last5Years",
    ];

    useEffect(() => {
        if (referentAccountData?.object?.children?.length) {
            // Met à jour les données référentes
            props.setReferentData(referentAccountData.object);

            const appChildId = Cookies.get("appChildId");
            console.log("Cookie appChildId:", appChildId);
            console.log("Children in referentAccountData:", referentAccountData.object.children);

            let childToShow = null;

            // Si un cookie est présent, tente de trouver l'enfant correspondant
            if (appChildId) {
                const childIdNumber = parseInt(appChildId, 10); // Convertir en nombre
                childToShow = referentAccountData.object.children.find(
                    (child) => child.childId === childIdNumber
                );
                if (childToShow) {
                    console.log("Child found in family:", childToShow);
                } else {
                    console.warn("No child matches the cookie ID, using default.");
                }
            }

            // Vérifie si l'enfant courant est valide
            const isValidCurrentChild = referentAccountData.object.children.some(
                (child) => child.childId === props.currentChildSelected?.childId
            );

            // Si aucun enfant trouvé via le cookie ou si l'enfant courant est invalide
            if (!childToShow && (!isValidCurrentChild || !props.currentChildSelected)) {
                childToShow = referentAccountData.object.children[0]; // Utiliser l'enfant par défaut
            }

            if (childToShow) {
                props.setCurrentChildSelected(childToShow);
            }
        } else {
            console.error("Children data is missing or invalid in referentAccountData.");
        }
    }, [referentAccountData, props]);


    const {currentMenuItem} = getCurrentMenuData(location.pathname, menuItems);

    const translatedMenuLabel = currentMenuItem
        ? t(`analyticsPages.analytics_menu_items.${currentMenuItem.label}`)
        : "";

    const formattedDate = getFormattedDate();

    const handleDateDropdownOptionChange = (
        selected: number,
        isProgress: boolean = false
    ) => {
        let states: string[];
        if (isProgress) {
            states = dateProgressFilterStates;
        } else {
            states = dateFilterStates;
        }

        const filter = states[selected];
        if (isProgress) {
            setDateProgressFilter(filter);
        } else {
            setDateFilter(filter);
        }
    };

    return (
        <div className="analytics">
            {props.isMobileScreenSize ? null : (
                <>
                    <div className="analytics__menu">
                        <AnalyticsMenu items={menuItems}/>
                    </div>
                </>
            )}

            <div className="analytics__stats">
                <div className="analytics__stats-header">
                    <div className="analytics__stats-header-space"></div>
                    <div className="analytics__stats-header-title">
                        {currentMenuItem ? (
                            <>
                                <img
                                    className="icon-title"
                                    src={currentMenuItem.iconViolet}
                                    alt={currentMenuItem.label}
                                />
                                {translatedMenuLabel}
                            </>
                        ) : (
                            t("analyticsPages.analytics_menu_items.summary") +
                            " " +
                            props?.currentChildSelected?.userName
                        )}
                    </div>

                    <div className="analytics__stats-header-date">
                        {(() => {
                            if (currentMenuItem) {
                                switch (currentMenuItem.route) {
                                    case "/analytics/progress":
                                        return (
                                            <Dropdown
                                                className="analytics__stats-header-date--dropdown"
                                                options={dateProgressFilterStates.map((state) =>
                                                    t(`analyticsPages.progress_page.${state}`)
                                                )}
                                                value={t(
                                                    `analyticsPages.progress_page.${dateProgressFilter}`
                                                )}
                                                onChange={(option) => {
                                                    const selectedValue = option.value;
                                                    const selectedIdx =
                                                        dateProgressFilterStates.findIndex(
                                                            (state) =>
                                                                t(`analyticsPages.progress_page.${state}`) ===
                                                                selectedValue
                                                        );
                                                    handleDateDropdownOptionChange(selectedIdx, true);
                                                }}
                                            />
                                        );

                                    case "/analytics/history":
                                    case "/analytics/activities":
                                        return (
                                            <Dropdown
                                                className="analytics__stats-header-date--dropdown"
                                                options={dateFilterStates.map((state) =>
                                                    t(`analyticsPages.history_page.${state}`)
                                                )}
                                                value={t(`analyticsPages.history_page.${dateFilter}`)}
                                                onChange={(option) => {
                                                    const selectedValue = option.value;
                                                    const selectedIdx = dateFilterStates.findIndex(
                                                        (state) =>
                                                            t(`analyticsPages.history_page.${state}`) ===
                                                            selectedValue
                                                    );
                                                    handleDateDropdownOptionChange(selectedIdx, false);
                                                }}
                                            />
                                        );
                                    case "/analytics/rewards":
                                    case "/analytics/search":
                                    case "/analytics/settings":
                                    case "/analytics/admin":
                                        return null;
                                    default:
                                        return `Today, ${formattedDate}`;
                                }
                            } else {
                                return `Today, ${formattedDate}`;
                            }
                        })()}
                    </div>
                </div>

                <div className="analytics__stats-content">
                    <Routes>
                        <Route
                            path="*"
                            element={
                                props.currentChildSelected ? (
                                    <Summary
                                        key={props.currentChildSelected.childId}
                                        currentChildSelected={props.currentChildSelected}
                                    />
                                ) : null
                            }
                        />
                        <Route
                            path="screen-time"
                            element={
                                props.currentChildSelected ? (
                                    <ScreenTime
                                        key={props.currentChildSelected.childId}
                                        currentChildSelected={props.currentChildSelected}
                                    />
                                ) : null
                            }
                        />
                        <Route
                            path="history"
                            element={
                                props.currentChildSelected ? (
                                    <HistoryAnalytics
                                        key={props.currentChildSelected.childId}
                                        currentChildSelected={props.currentChildSelected}
                                        dateFilter={dateFilter}
                                    />
                                ) : null
                            }
                        />
                        <Route
                            path="activities"
                            element={
                                props.currentChildSelected ? (
                                    <ActivitiesAnalytics
                                        key={props.currentChildSelected.childId}
                                        currentChildSelected={props.currentChildSelected}
                                        dateFilter={dateFilter}
                                    />
                                ) : null
                            }
                        />
                        <Route
                            path="skill-map"
                            element={
                                props.currentChildSelected ? (
                                    <SkillMap
                                        key={props.currentChildSelected.childId}
                                        isMobileScreenSize={props.isMobileScreenSize}
                                        currentChildSelected={props.currentChildSelected}
                                    />
                                ) : null
                            }
                        />
                        <Route
                            path="progress"
                            element={
                                props.currentChildSelected ? (
                                    <Progression
                                        key={props.currentChildSelected.childId}
                                        currentChildSelected={props.currentChildSelected}
                                        dateFilter={dateProgressFilter}
                                    />
                                ) : null
                            }
                        />
                        <Route
                            path="recommendations"
                            element={
                                props.currentChildSelected ? (
                                    <Recommendations
                                        key={props.currentChildSelected.childId}
                                        currentChildSelected={props?.currentChildSelected}
                                        referentData={props.referentData}
                                    />
                                ) : null
                            }
                        />
                        <Route
                            path="rewards"
                            element={
                                props.currentChildSelected ? (
                                    <Rewards
                                        key={props.currentChildSelected.childId}
                                        currentChildSelected={props?.currentChildSelected}
                                        referentData={props.referentData}
                                    />
                                ) : null
                            }
                        />
                        <Route
                            path="search"
                            element={
                                props.currentChildSelected ? (
                                    <SearchAnalytics
                                        key={props.currentChildSelected.childId}
                                        currentChildSelected={props.currentChildSelected}
                                        referentData={props.referentData}
                                    />
                                ) : null
                            }
                        />
                        <Route
                            path="settings"
                            element={
                                props.currentChildSelected ? (
                                    <>
                                        <SettingsAnalytics
                                            key={props.currentChildSelected.childId}
                                            currentChildSelected={props.currentChildSelected}
                                            referentData={props.referentData}
                                            setReferentData={props.setReferentData}
                                        />
                                    </>
                                ) : null
                            }
                        />
                        <Route
                            path="admin"
                            element={
                                props.currentChildSelected ? (
                                    <>
                                        <p
                                            style={{
                                                textAlign: "center",
                                                alignSelf: "center",
                                                marginBottom: "48px",
                                            }}
                                        >
                                            Users stats
                                        </p>
                                        <Admin/>
                                    </>
                                ) : null
                            }
                        />
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default Analytics;
