import Cookies from "js-cookie";
import {ChildBaseModel} from "../../interfaces/child-interfaces/child-interface";

export const handleLanguage = (
    langId: string | null,
    changeLanguage: (lang: string) => void
) => {
    if (langId === null) {
        Cookies.remove("appLangId");
        return;
    }

    Cookies.set("appLangId", langId, {
        expires: 7,
        secure: true,
    });

    const langMap: Record<string, string> = {
        "0": "en",
        "1": "fr",
        "2": "de",
    };

    const newLang = langMap[langId] || "en";
    changeLanguage(newLang);
};

export const handleChildId = (appChildId: string | null) => {
    if (appChildId) {
        Cookies.set("appChildId", appChildId, {
            expires: 1,
            secure: true,
        });
    }
};

export const handleFamilyData = (
    referentData: any,
    currentChildSelected: any,
    setCurrentChildSelected: any,
    changeLanguage: (lang: string) => void
) => {
    if (!referentData || currentChildSelected) return;

    const appLangId = Cookies.get("appLangId");
    const appChildId = Cookies.get("appChildId");

    if (appChildId) {
        const childToShow = referentData.childrenData.find(
            (child) => child.childId === appChildId
        );
        if (childToShow) {
            setCurrentChildSelected(childToShow);
        } else {
            setCurrentChildSelected(referentData.childrenData[0]);
        }
    } else {
        setCurrentChildSelected(referentData.childrenData[0]);
    }

    if (appLangId) {
        const langMap: Record<string, string> = {
            "0": "en",
            "1": "fr",
            "2": "de",
        };

        const newLang = langMap[appLangId] || "en";
        changeLanguage(newLang);
    }
};

export const findChildIndex = (
    childId: number,
    children: ChildBaseModel[]
): number => {
    const index = children.findIndex((child) => child.childId === childId);
    return index !== -1 ? index : 0;
};

